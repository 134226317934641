// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascripts and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("@glidejs/glide")
import "../css/tailwind.css";
import Glide from '@glidejs/glide';
require("@glidejs/glide/dist/css/glide.core.min.css")
console.log("js222");
var menuShown = false;
document.addEventListener("turbolinks:load", ()=>{
    if (document.querySelectorAll(".glide").length) {
        new Glide(".glide").mount();
    }
    if (document.querySelectorAll(".glide-intro").length) {
        var glide = new Glide('.glide-intro', {
            type: 'carousel',
            autoplay: 2500,
            gap: 0,
            breakpoints: {
                800: {
                    perView: 2
                },
                480: {
                    perView: 1
                }
            }
        })
        glide.mount();
    }
    if (document.querySelectorAll("#btn-buy").length) {
        $("#btn-buy").click(function () {
            $("#buy-form-container").removeClass("h-0").addClass("h-auto");
            $("#btn-buy").fadeOut(200);
        });
    }
    if (document.querySelectorAll("#menuToggle").length) {
        $("#menuToggle").click(function () {
            menuShown ? $("#mobile-menu").fadeOut(200) : $("#mobile-menu").fadeIn(200);
            menuShown = !menuShown;
        });
        $("#menuBackdrop").click(function () {
            menuShown ? $("#mobile-menu").fadeOut(200) : $("#mobile-menu").fadeIn(200);
            menuShown = !menuShown;
        });
    }
    if (document.querySelectorAll("#human-checkbox").length) {
        $("#human-checkbox").click(function () {
            var submbut = $("#submit-button");
            if($("#human-checkbox").is(":checked")) {
                submbut.removeClass("opacity-20");
                submbut.removeAttr("disabled");
            } else {
                submbut.addClass("opacity-20");
                submbut.addAttr("disabled");
            }
        });
    }

});


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
